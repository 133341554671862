import React from "react"
import { Link } from "gatsby"
import * as FaIcons from "react-icons/fa"

const NewNewsCard = ({
  data: {
    articleDetails: {
      articleExcerpt,
      originalContent,
      outsideArticleLink,
      pinArticle,
    },
    title,
    featuredImage,
    content,
    slug,
  },
  ribbon,
}) => {
  return (
    <div className="news-card">
      {ribbon && (
        <div class="ribbon ribbon-top-left">
          <span>
            {/* <FaIcons.FaThumbtack className="pin-icon" />  */}
            Featured
          </span>
        </div>
      )}
      <img src={featuredImage?.node?.mediaItemUrl} alt="" />
      <div className="title-wrap">
        <h5>
          {title.length > 50 ? title.substring(0, 50).concat("....") : title}
        </h5>
      </div>
      <div
        className="excerpt"
        dangerouslySetInnerHTML={{
          __html: articleExcerpt.substring(0, 100).concat("...."),
        }}
      />
      <Link
        target={originalContent == "Outside_Article" ? "_blank" : ""}
        className="btn btn-blue"
        to={
          originalContent == "Outside_Article"
            ? outsideArticleLink.url
            : `/news/${slug}`
        }
      >
        Read More
      </Link>
    </div>
  )
}

export default NewNewsCard
