import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import NewsCard from "../components/NewsCard"
import NewNewsCard from "../components/NewNewsCard"

import Seo from "../components/seo"

import classroomPic from "../../images/pages/news/pic-1.png"

const articleList = ({
  pageContext: { posts, pinnedPosts, numberOfPages, currentPage },
}) => {
  console.log({ currentPage })
  return (
    <section id="news">
      <Seo
        title="News"
        description="The Latest ideas, thoughts and research about how high school education can build back better to serve students and communities. Education reform."
      />
      <div className="one">
        <div className="container">
          <div className="content full-screen">
            <div className="left">
              <img src={classroomPic} alt="" className="pic" />
            </div>
            <div className="right">
              <div className="wrap-content">
                <h2>The Latest</h2>
                <ul>
                  {posts.slice(0, 4).map((post, index) => {
                    const {
                      articleDetails: {
                        articleExcerpt,
                        originalContent,
                        outsideArticleLink,
                      },
                      title,
                      featuredImage,
                      content,
                      slug,
                    } = post.node
                    return (
                      <li>
                        <Link
                          target={
                            originalContent == "Outside_Article" ? "_blank" : ""
                          }
                          to={
                            originalContent == "Outside_Article"
                              ? outsideArticleLink.url
                              : `/news/${slug}`
                          }
                        >
                          {title}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="content mobile">
            <div className="wrap-content">
              <h2>The Latest</h2>
              <ul>
                {posts.slice(0, 4).map((post, index) => {
                  return (
                    <li>
                      <Link to={`/news/${post.node.slug}`}>
                        {post.node.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="two">
        <div className="container">
          <div className="article-grid">
            {currentPage === 1 &&
              pinnedPosts.map(post => {
                return <NewNewsCard ribbon={true} data={post.node} />
              })}
            {posts.map(post => {
              return <NewNewsCard ribbon={false} data={post.node} />
            })}
          </div>
          <div className="pagination">
            {Array.from({ length: numberOfPages }).map((page, index) => {
              return (
                <div
                  className="num-wrap"
                  key={index}
                  style={{
                    background: index + 1 === currentPage ? "#f2aa1d" : "white",
                    color: index + 1 === currentPage ? "white" : "inherit",
                  }}
                >
                  <Link
                    to={index === 0 ? `/news` : `/news/${index + 1}`}
                    className="page-num"
                  >
                    {index + 1}
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default articleList
